import React, {useEffect, useState}           from 'react'
import {graphql, PageProps}                   from 'gatsby'
import {AllSanityHelpTopic, HelpArticleGroup} from '@open-law/fab/shared-entities'
import Layout                                 from '../containers/layout'

export const query = graphql`
    query {
        site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
            primaryColor {
                hex
            }
            secondaryColor {
                hex
            }
            title
            openGraph {
                title
                description
                image {
                    ...SanityImage
                }
            }
        }
        quickstartCategory: allSanityHelpTopic(filter: {helpArticles: {elemMatch: {helpArticle: {topics: {elemMatch: {title: {eq: "Quick Start"}}}}}}}, limit: 1) {
            edges {
                node {
                    helpArticles {
                        helpArticle {
                            title
                            slug {
                                current
                            }
                            _rawBody
                            description
                        }
                    }
                    title
                    description
                }
            }
        }
    }
`

interface HelpPageProps extends PageProps {
    data: {
        quickstartCategory: AllSanityHelpTopic
        menuItems: any
    }
}

const HelpPage: React.FC<HelpPageProps> = (props) => {
    const {data} = props

    const [topicGroups, setTopicGroups] = useState<HelpArticleGroup[] | undefined>()

    useEffect(() => {
        if (data.quickstartCategory) {
            const groups = data.quickstartCategory.edges
            const listOfTopicGroups: HelpArticleGroup[] = groups.map((edge) => {
                const node = edge.node
                const articles = node.helpArticles.map((articles) => articles.helpArticle)
                return {
                    title: node.title,
                    description: node.description,
                    helpArticles: articles
                }
            })
            setTopicGroups(listOfTopicGroups)
        }
    }, [])

    const renderHelpTopics = (group: HelpArticleGroup, key: number) => {
        return (
            <section key={key} className="group-topic-grid-layout">
                <div className="relative bg-gray-50 pt-16 pb-20 px-4 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8">
                    <div className="absolute inset-0">
                        <div className="bg-white h-1/3 sm:h-2/3"></div>
                    </div>
                    <div className="relative max-w-7xl mx-auto">
                        <div className="text-center">
                            <h2 className="text-3xl tracking-tight font-extrabold text-gray-900 sm:text-4xl">
                                {group.title}
                            </h2>
                            <p className="mt-3 max-w-2xl mx-auto text-xl text-gray-500 sm:mt-4">
                                {group.description}
                            </p>
                        </div>
                        <div className="mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none">
                            {
                                group.helpArticles && group.helpArticles.map((article, idx) => (
                                    <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
                                        <div className="flex-shrink-0">
                                            <img className="h-48 w-full object-cover"
                                                 src={article.projectImage ? article.projectImage.asset.url : 'https://images.unsplash.com/photo-1496128858413-b36217c2ce36?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1679&q=80'}
                                                 alt={article.projectImage ? article.projectImage.alt : ''}/>
                                        </div>
                                        <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                                            <div className="flex-1 flex justify-end">
                                                <a href={'/help-guides/' + article.slug.current} className="block mt-2">
                                                    <p className="text-xl font-semibold text-gray-900">
                                                        {article.title}
                                                    </p>
                                                    <p className="mt-3 text-base text-gray-500">
                                                        {article.description}
                                                    </p>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
                <div className="grid gap-4 grid-cols-3">


                </div>
            </section>
        )
    }

    return (
        <Layout navMenuItems={data.menuItems} textWhite={true}>
            <div className="quickstart-wrapper">
                {topicGroups && topicGroups.map((group, idx) => renderHelpTopics(group, idx))}
            </div>
        </Layout>
    )
}

export default HelpPage
